.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary-color);
  color: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.cookie-notice-text {
  margin: 0;
  flex-grow: 1;
  font-size: 14px;
  padding-right: 20px;
  color: #fff;
}

.cookie-notice-button {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

