section {
    padding: 5rem 0;
}
  
section h2 {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: bold;
}