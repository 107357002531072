.navbar {
  background-color: var(--primary-color);
  padding: 0;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar > .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--white-color);
  padding: 0;
  margin-right: 2rem;
}

.navbar-nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-link {
  color: var(--white-color);
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-link:hover {
  color: var(--accent-color);
}

.navbar-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.language_selector {
  display: flex;
  align-items: center;
}

.language_selector button {
  margin-left: 0.5rem;
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: var(--primary-color);
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    padding: 0.5rem 0;
  }

  .language_selector {
    margin-top: 1rem;
  }
}
