/* Cores */
:root {
  --primary-color: #007BFF;
  --secondary-color: #17A2B8;
  --accent-color: #6F42C1;
  --white-color: #FFFFFF;
  --light-gray: #F8F9FA;
  --dark-gray: #343A40;
  --border-gray: #CED4DA;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--white-color);
}

main {
  /* Remova ou comente a linha abaixo */
  /* margin-top: -60px; */
}

.navbar {
  background-color: var(--primary-color);
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--white-color);
}

.nav-link {
  color: var(--white-color);
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--accent-color);
}

#inicio {
  position: relative;
}

#inicio::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

#inicio .container {
  position: relative;
  z-index: 2;
}

#inicio h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: var(--white-color);
}

#inicio p {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--white-color);
}

.hero {
  background-color: var(--light-gray);
}

.hero .cta-button {
  background-color: var(--accent-color);
  color: var(--white-color);
}

/* Cards */
.card {
  background-color: var(--light-gray);
  border: 1px solid var(--primary-color);
}

.card-title {
  color: var(--dark-gray);
}

.card-icon {
  color: var(--secondary-color);
}

/* Forms */
.form-control {
  border: 1px solid var(--border-gray);
}

.form-control:focus {
  border-color: var(--primary-color);
}

.submit-button {
  background-color: var(--accent-color);
  color: var(--white-color);
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.footer a {
  color: var(--white-color);
}

/* Buttons */
.button {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.button:hover {
  background-color: var(--secondary-color);
}

/* General Text */
h1, h2, h3, h4, h5, h6 {
  color: var(--dark-gray);
}

p {
  color: var(--dark-gray);
}

/* Links */
a {
  color: var(--primary-color);
}

a:hover {
  color: var(--accent-color);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px; /* Deve ser igual à altura da navbar */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Alinhar textos centralizados para traduções */
.texto_localizado {
  text-align: center;
}
