.footer {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 2rem 0;
  }
  
  .footer h3 {
    color: var(--white-color);
    margin-bottom: 1rem;
  }
  
  .footer p,
  .footer ul li {
    color: var(--white-color);
  }
  
  .footer hr {
    border-color: var(--white-color);
    margin: 1.5rem 0;
  }