.hero-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 60px;
}

.parallax-container {
    min-height: 100vh;
}

.text-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.text-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.text-container p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: var(--dark-gray);
}

.cta_button {
    background-color: var(--accent-color);
    color: var(--white-color);
    padding: 10px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta_button:hover {
    background-color: var(--secondary-color);
}
