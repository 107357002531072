.custom_form_container {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Aumentado de 800px para 1000px */
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text_area {
  flex: 1;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  border-radius: 8px 0 0 8px;
}

.rotating_text {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
}

.form_area {
  flex: 2;
  padding: 20px;
  background-color: #ffffff;
}

.form_group {
  margin-bottom: 15px;
}

.form_group label {
  display: block;
  margin-bottom: 5px;
  color: #333333;
}

.form_group input,
.form_group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form_group textarea {
  resize: vertical;
  height: 120px;
}

.custom_file_upload {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.image_preview {
  margin-top: 10px;
}

.image_preview img {
  max-width: 100%;
  max-height: 150px; /* Limita a altura da imagem */
  object-fit: cover;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.form_actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form_actions button,
.form_actions .button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .custom_form_container {
    flex-direction: column;
  }

  .text_area,
  .form_area {
    width: 100%;
  }
}

.custom_form_container > * {
  margin-bottom: 15px;  /* Garante que os filhos tenham espaço */
}
