.about_section {
  padding: 5rem 0;
}

.about_title {
  text-align: center;
  margin-bottom: 3rem;
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: bold;
}

.about_content {
  background-color: var(--white-color);
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about_content h2 {
  color: var(--dark-gray);
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.about_content p {
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: var(--dark-gray);
  font-size: 1.1rem;
}

.servicos_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.servico_card {
  background-color: var(--white-color);
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.servico_icon {
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.servico_titulo {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--dark-gray);
}

.servico_descricao {
  color: var(--dark-gray);
  font-size: 1rem;
}

@media (max-width: 768px) {
  .about_section {
    padding: 3rem 0;
  }
  
  .about_content {
    padding: 2rem;
  }

  .about_title {
    font-size: 2rem;
  }

  .about_content h2 {
    font-size: 1.5rem;
  }

  .about_content p,
  .about_content ul li {
    font-size: 1rem;
  }

  .servicos_grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .servicos_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1201px) {
  .servicos_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Adicione estas regras ao final do arquivo CSS */

/* Ocultar os dois pontos após os labels */
.card .card-name strong,
.card .card-subtitle strong {
  display: none;
}

/* Ajustar o estilo do título e subtítulo */
.card .card-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.card .card-subtitle {
  font-size: 1rem;
  color: #666;
}

/* Estilizar o ícone */
.card .card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-bottom: 1rem;
}

.card .card-image img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

/* Ajustar o layout do card */
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card .card-content {
  text-align: center;
}

/* Remover a linha que separa os botões (caso ainda exista) */
.card .card-actions {
  border-top: none;
  padding-top: 0;
}

.navbar {
  background-color: var(--primary-color);
  padding: 0.5rem 0;
}

body {
  padding-top: 56px; /* Ajuste este valor de acordo com a altura da sua navbar */
}
