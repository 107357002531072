.contato-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.contato-form,
.contato-info {
  width: 100%;
}

.contato-info {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
}

.custom-form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-area {
  flex: 1;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.rotating-text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.form-area {
  flex: 2;
  padding: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: var(--dark-gray);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  height: 120px;
  resize: vertical;
}

.submit-button,
.cta_button {
  background-color: var(--accent-color);
  color: var(--white-color);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover,
.cta_button:hover {
  background-color: var(--secondary-color);
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .custom-form-container {
    flex-direction: column;
  }

  .text-area,
  .form-area {
    flex: auto;
  }
}

.contato-header {
  text-align: center;
  margin-bottom: 2rem;
}

.contato-header h2 {
  color: #007bff;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contato-header p {
  color: #333333;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.contato-container {
  width: 100%;
  max-width: 1200px; /* Aumentado de 800px para 1000px */
  margin: 2rem auto;
}

.contato-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 768px) {
  .contato-wrapper {
    padding: 1rem;
  }
}
